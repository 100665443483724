import './styles.scss';



let Layout = ({ children }) => {
    return (
        <div className="page-container">      
            {children}
        </div>
    );
}

export default Layout;