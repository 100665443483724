import React,  {useState}  from 'react';
import validator from 'validator';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-scroll';
import { useFathom } from 'fathom-react';

import './styles.scss';

import logo from '../../assets/logo.svg';
import avatar from '../../assets/usman.png';
import downbtn from '../../assets/down-arrow.svg';
import fakeemail from '../../assets/152.jpg';

const Home = () => {

   const fathom = useFathom();

   const [enteredEmail, setEnteredEmail] = useState('');
   const [hasValidEmailAddress, setHasValidEmailAddress] = useState(false);
   const [hasSubmitted, setHasSubmitted] = useState(false);

   const [hasCaptcha, setHasCaptcha] = useState(false);
   const [hasSubscribed, setHasSubscribed] = useState(false);
   const [isSubscribing, setIsSubscribing] = useState(false);


   const validateEmail = (e) => {
      e.preventDefault();
      setHasSubmitted(true);
     // console.log(hasEnteredEmail);
      if (validator.isEmail(enteredEmail)) {
         setHasValidEmailAddress(true);
         //subscribe button pressed
         fathom.goal('PND6N6XQ', 0);
      } else {
         setHasValidEmailAddress(false);
      }
   }

   const subscribeEmail = () => {
      let urlToFetch = 'https://api.considerable.cx?email_address=' + enteredEmail;
      fetch(urlToFetch, {
        method: 'GET',
        headers: { 'Content-type': 'application/x-www-form-urlencoded'},         
      }).then(function(response) {

         //track successful subscription
         fathom.goal('MX2CFECH', 0);
      });
   }

   const onCaptchaChange = (e) => {
      setHasCaptcha(true);
      subscribeEmail();
   }

   return (
      <>
      <div className="header">
         <div className="header-content">
            <div className="left">
               <img className="logo" src={logo} alt="Considerable CX Logo" />
            </div>
            <div className="right">
               <h1>Considerable CX</h1>
               <h3>A semi-weekly de-construction of notable CX, design patterns, and internet culture.</h3>
            </div>
         </div>
      </div>
      <div className="main">
         <div className={classNames("form-container", {'shown': hasCaptcha === false})}>
            <form onSubmit= {(e) => validateEmail(e)}>
               <div className="inline">
                  <button type="submit" name="button" disabled={hasCaptcha} >
                     <span className={classNames("default", {'greyed': hasCaptcha})}>subscribe</span>
                  </button>
                  <input 
                     type="email" 
                     name="subscriber_email" 
                     required="" 
                     placeholder="Enter your email address"  
                     autoComplete="off"
                     onChange={(e) => setEnteredEmail(e.target.value)}
                  />
               </div>
               <input type="hidden" name="token" value={process.env.REACT_APP_OCTOKEY} />
            </form>  
            <div className={classNames("email-feedback", {'shown': !hasValidEmailAddress && hasSubmitted})}>Enter a valid e-mail address</div>
         </div>
         <div className={classNames("captcha-block", {'show-flex': hasValidEmailAddress && hasCaptcha === false})}>
            <ReCAPTCHA
               sitekey={process.env.REACT_APP_SITE_RECAPTCHA_KEY}
               size="normal"
               onChange={onCaptchaChange}
            />
         </div>
         <div className={classNames("success", {'shown': hasCaptcha === true || hasSubscribed === true || isSubscribing === true})}>
            Thanks for signing up for Considerable CX. I've sent you an email to confirm your subscription. In the mean-time, check out the <a href="" alt="latest issue" onClick={()=> fathom.goal('KKFJK7E9',0)}>latest issue</a> in the archive.
         </div>

         <div className="body-copy">
            Join 1,201 designers, developers, and product people in reading a semi-weekly curated email of notable customer experiences, design patterns, internet culture, trends, and ethics.
         </div>
         <div className="genome">
            <div className="avatar">
               <img src={avatar} alt="" />
            </div>
            <div className="name">
               Published by <a href="https://twitter.com/uxfarman" target="_new" onClick={()=> fathom.goal('OENMKKCI',0)}>Usman Farman</a>
            </div>
         </div>
         <div className="issue-button">
            <Link activeClass="active" to="preview" spy={false} smooth={true} duration={300} onClick={()=> fathom.goal('9D1QRW4X',0)}>
               <button type="button">
                  <div className="title">Preview the latest issue</div>
                  <div className="down-btn"><img src={downbtn} alt="" /></div>
               </button>
            </Link>
         </div>
         <div className="issue-archive">
            <div className="window">
               <div className="window-buttons">
                  <div className="red" />
                  <div className="yellow" />
                  <div className="green" />
               </div>
               <div className="window-title">
                  Issue #1: Tech Goes Bold - Aug 28, 2021
               </div>
               <div className="window-block">&nbsp;</div>
            </div>

            <div className="email-preview" id="preview">
               <div className="archive-button">
                     <a href="/issues/latest/" onClick={()=> fathom.goal('AMBJGRIR',0)}><span>Read the latest in the archive</span></a>
               </div>
               <div className="bottom-gradient"></div>
               <img className="mail-preview-image" src={fakeemail} alt="" />
            </div>

         </div>
      </div>
      <div className="footer">
         Considerable CX &copy; 2021 Happy When Fed, LLC.
      </div>
      </>
   );
}

export default Home;