import React from 'react';
import Layout from './layout/';
import Home from './components/home';
import { BrowserRouter as Switch, Route, Redirect, useLocation} from 'react-router-dom';
import Fathom from 'fathom-react';


function App() {
  const location = useLocation();
  
  return (
   <>
   <Fathom siteId="ZVMIOZJX" customDomain="aardvark.considerable.cx">
    <Layout>
      <Switch location={location} key={location.pathname}>
        <Route path="/" component={Home} />
      </Switch>
    </Layout>
    </Fathom>
   </>
  );
}

export default App;
